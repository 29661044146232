import {
  sendBreadcrumbToSentry,
  breadcrumbData,
  handleError,
} from "../services/commonUsefulFunctions";
import * as Sentry from "@sentry/browser";
import { removeKeyFromObject } from "../lib/objectFunctions";

/**
 * The any types are fine for now. Once all the broadcasts have been updated, we can change to the following:
 *
 * ```ts
 * type Broadcast<TArgs = [], TReturn = void> = { ... }
 * ```
 */
type Broadcast<TArgs = any[], TReturn = any> = {
  args: TArgs
  returns: TReturn
}

export default function broadcastGenerator<T extends Record<string, Broadcast>>() {
  return {
    subscriptions: {} as Partial<{[TKey in keyof T]: (...args: T[TKey]["args"]) => T[TKey]["returns"]}>,

    subscribe<TKey extends keyof T>(event: TKey, callback: (...args: T[TKey]["args"]) => T[TKey]["returns"]) {
      if (this.isSubscribedToEvent(event) && this.subscriptions[event] !== callback) {
        handleError(new Error(`Overwriting existing subscription: ${event}`));
      }
      this.subscriptions[event] = callback;
    },

    unsubscribe(event: keyof T) {
      this.subscriptions = removeKeyFromObject(this.subscriptions, event);
    },

    publish<TKey extends keyof T>(event: TKey, ...args: T[TKey]["args"]) {
      sendBreadcrumbToSentry({
        category: "Broadcast",
        message: event,
        // include args if none are array/object, to mitigate 413 error risk
        data: breadcrumbData(args),
      });
      const subscription = this.subscriptions[event];
      if (subscription) {
        return subscription(...args);
      } else {
        sendBreadcrumbToSentry({
          category: "Broadcast",
          message: `${event} doesn't exist`,
          level: Sentry.Severity.Warning,
        });
      }
    },

    isSubscribedToEvent(event: keyof T) {
      return Boolean(this.subscriptions[event]);
    },
  };
}
