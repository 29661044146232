import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./js/config/reduxConfig";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/browser";
import { RewriteFrames } from "@sentry/integrations";
import * as serviceWorker from "./serviceWorker";
import "./js/styles/errorBoundryStyles.css";
import "./js/styles/tailwind.css";
import "./js/styles/dnd_styles.css";
import "./js/styles/main_calendar.css";
import "./js/styles/schedulingAssistant.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./js/styles/dateRangePicker.css";
import "./App.css";
import "./js/styles/default-styles.css";
import "./js/styles/sso-styles.css";
import "./js/styles/vimcalea-login.css";
import "./js/styles/light-mode.css";
import "./js/styles/style.css";
import "./js/styles/blurs.css";
import "./js/styles/settings.css";
import "./js/styles/alerts.css";
import "./js/styles/reactSelectStyles.css";
import "./js/styles/availabilityPanel.css";
import "./js/styles/signup.css";
import "./js/styles/mobile-signup.css";
import "./js/styles/splitCalendarStyles.css";
import "./js/styles/eventForm.css";
import "./js/styles/quill.css";
import "./js/styles/expandedView.css";
import "./js/styles/windows.css";
import "./js/styles/magicLinkStyle.css";
import "./js/styles/maestroStyle.css";
import "./js/styles/personal-links.css";
import "./js/styles/metrics.css";
import "./js/styles/mobile.css";
import "./js/styles/dark-mode.css";
import "./js/styles/modal.css";
import "./js/styles/override-styles.css";
import "./js/styles/tooltips.css";
import "./js/styles/tooltips-dark-mode.css";
import "./js/styles/outlookStyles.css";
import "./js/styles/buttons.css";
import "./js/styles/buttonsV2.css";
import "./js/styles/clientStyles.css";
import "./js/styles/scrollbar.css";
import "./js/styles/animation.css";
import "./js/styles/aiStyles.css";
import "./js/styles/groupVoteLinkStyles.css";
import "./js/styles/groupVoteSpreadSheet.css";
import "./js/styles/spinner.css";
import "./js/styles/vimcalEAForm.css";
import "./js/styles/eventThreads.css";
import {
  isMenuBar,
  wrapperErrorHandler,
  windowOnErrorHandler,
} from "./js/services/commonUsefulFunctions";
import ErrorBoundary from "./js/components/ErrorBoundary";
import DefaultLoadingScreen from "./js/components/defaultLoadingScreen";
import componentLoader from "./js/lib/componentLoader";
import { initMixPanel } from "./js/lib/mixpanelTracking";
import { ENVIRONMENTS } from "./js/lib/vimcalVariables";

window.onerror = wrapperErrorHandler;

window.addEventListener("error", windowOnErrorHandler);

const env = process.env.REACT_APP_CLIENT_ENV;

initMixPanel();

Sentry.init({
  dsn:
    env === ENVIRONMENTS.PRODUCTION || env === ENVIRONMENTS.STAGING
      ? "https://b47ddbd063f24fa2a9045a947bc428ef@sentry.io/2190664"
      : "",
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: env,
  integrations: [new RewriteFrames()],
  maxBreadcrumbs: 50,
  ignoreErrors: ["Non-Error promise rejection captured"], // https://github.com/getsentry/sentry-javascript/issues/3440
});

const MenuBarApp = lazy(() =>
  /* webpackChunkName: 'menuBar' */
  componentLoader({
    lazyComponent: () => import("./MenuBarApp"),
    componentName: "MenuBarApp",
  })
);
const App = lazy(() =>
  /* webpackChunkName: 'App' */
  componentLoader({
    lazyComponent: () => import("./App"),
    componentName: "App",
  })
);

const project = isMenuBar() ? <MenuBarApp /> : <App />;
// const project = <MenuBarApp/>; // FOR DEV

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<DefaultLoadingScreen />}>{project}</Suspense>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
