// eslint-disable-next-line @typescript-eslint/no-empty-function
global.$RefreshReg$ = () => { }; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
// eslint-disable-next-line @typescript-eslint/no-empty-function
global.$RefreshSig$$ = () => () => { }; // eslint-disable-line no-restricted-globals

import React from "react";

interface CircleWithColorProps {
  color: string | undefined
  colorName?: string
  size?: number
}

export default function CircleWithColor({ color="transparent", colorName, size = 15 }: CircleWithColorProps) {
  return (
    <div className="flex flex-row items-center">
      <div
        style={{
          border: color === "transparent" ? "dashed 1px gray" : "",
          backgroundColor: color,
          width: size,
          height: size,
          borderRadius: "50%",
          whiteSpace: "nowrap",
        }}
      />

      {colorName ? (
        <div
          className="ml-2 whitespace-nowrap truncate-text"
          style={{ width: "calc(100% - 23px)" }}
        >
          {colorName}
        </div>
      ) : null}
    </div>
  );
}
